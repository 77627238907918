/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Con el paso a hosting propio perdí una de las herramientas más importantes del blogger, según mi modo de ver, la que te enlaza con los blogs amigos, los interesantes, los que lees; el blogroll."), "\n", React.createElement(_components.p, null, "Resulta que Wordpress ya no considera la sección de enlaces coherente en su núcleo y ahora es necesario instalar el plugin Link Manager para recuperar esta opción. Pues nada, ya está instalado y funcionando, el siguiente paso es intentar ", React.createElement(_components.a, {
    href: "http://www.wasab.dk/morten/blog/archives/2004/07/05/wordpress-plugin-foaf-output"
  }, "dar un poco de inteligencia a estos enlaces"), ", algo de web semántica y de lo que se conoce como friend-of-a-friend o símplemente FOAF. A ver donde llegamos."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
